<template>
  <div>
    <h1 class="text-h4">Управление параметрами номенклатурны</h1>
    <v-divider class="my-2"></v-divider>
    <v-form v-model="form.valid" :disabled="busy" @submit.prevent="submitForm">
      <v-card>
        <v-card-title>Редактирование единицы измерения</v-card-title>
        <v-card-text>
          <v-text-field
            v-model="form.data.name"
            label="Название"
            counter="150"
            :rules="form.rule.name"
          />
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn :to="{name: 'uom.list'}">Отмена</v-btn>
          <v-btn color="primary" :disabled="!form.valid" type="submit">Отправить</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </div>
</template>

<script>
import { dashboardPageCommonAttributes } from '@/mixins/DashboardPageCommonAttributes'
import { ruleNumeric, ruleRequired } from '@/utils/FormRules'
import { mapActions, mapState } from 'vuex'
import UnitOfMeasureUpdateForm from '@/model/uom/UnitOfMeasureUpdateForm'

export default {
  ...dashboardPageCommonAttributes,
  name: 'UnitOfMeasureEditPage',
  props: {
    id: {
      type: [String],
      required: true
    }
  },
  data() {
    return {
      form: {
        valid: true,
        data: {
          name: ''
        },
        rule: {
          name: [ruleRequired()],
          sortWeight: [ruleNumeric()]
        }
      }
    }
  },
  computed: {
    ...mapState('uom_edit', ['item', 'busy'])
  },
  created() {
    this.fetchData({ id: this.id })
      .then(() => {
        this.form.data.name = this.item.name
      })
  },
  methods: {
    ...mapActions('uom_edit', ['fetchData', 'updateUnitOfMeasure']),
    submitForm() {
      const form = UnitOfMeasureUpdateForm.create({ ...this.form.data })

      this.updateUnitOfMeasure({ form })
        .then(() => this.$router.push({ name: 'uom.list' }))
    }
  }
}
</script>
